import React, { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import clientConfig from "../client-config";
import { PuffLoader } from "react-spinners";

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [authToken, setAuthToken] = useState(null);

  useEffect(() => {
    const getAuthToken = async () => {
      try {
        const response = await axios.post(
          `${clientConfig.siteUrl}/wp-json/jwt-auth/v1/token`,
          {
            // FIXME: move to .env
            username: "admin",
            password: "ogPcbCA#9uR%i&9wKt",
          }
        );
        setAuthToken(response.data.token);
      } catch (err) {
        console.error("Error fetching auth token:", err);
      } finally {
        setIsLoading(false);
      }
    };

    getAuthToken();
  }, []);

  if (isLoading) {
    return (
      <div style={spinnerStyle}>
        <PuffLoader size={100} color={"#b0b2e4"} loading={isLoading} />
      </div>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        authToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// CSS style for centering the spinner
const spinnerStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh", // Full screen height for better centering
};

export default AuthProvider;
