import React from "react";
import ReactPlayer from "react-player";
import "../styles/VideoSection.css";

const VideoSection = () => {
	return (
		<div className="container-fluid py-5 video-section">
			<div className="row justify-content-center">
				<div className="col-12 col-lg-10">
					<div className="video-wrapper">
						<div className="player-wrapper">
							<ReactPlayer
								className="react-player"
								url="https://www.youtube.com/watch?v=9zMD1VNlnmo"
								playing
								muted
								width="100%"
								height="100%"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default VideoSection;
