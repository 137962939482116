import React, { useEffect } from "react";
import "../styles/FeaturesHighlight.css";
import { MoveRight } from "lucide-react";
import accurateDataImage from "../assets/images/features-highlight/accurate-data.png";
import researchImage from "../assets/images/features-highlight/research.png";
import contextualMemoryImage from "../assets/images/features-highlight/contextual-memory.png";
import aiAssistantImage from "../assets/images/features-highlight/ai-assistant.png";
import anyConfusionImage from "../assets/images/features-highlight/any-confusion.png";
import AOS from "aos";
import "aos/dist/aos.css";

const FeaturesHighlight = () => {
	const AIRA_SIGNUP_LINK = "https://app.helloaira.io/signup";
	const AIRA_APP_LINK = "https://app.helloaira.io/";

	useEffect(() => {
		AOS.init({
			duration: 750, // Animation duration
			easing: "ease-out", // Easing function
			once: true, // Whether animation should happen only once
		});
	}, []);
	return (
		<div className="features-highlight py-5 mt-5">
			<div className="container pb-5">
				{/* Accurate Data Extraction */}
				<div className="row">
					<div className="col-md-6 col-sm-12 d-flex justify-content-center align-items-center feature order-md-first order-last mt-md-0 mt-4">
						<div data-aos="fade-up" data-aos-delay="250">
							<h2 className="fw-bold mb-4">Accurate Data Extraction</h2>
							<p className="mb-4">
								Streamline your workflow by eliminating manual data entry. Our
								powerful AI-driven technology ensures precise and efficient data
								extraction, creating your candidate profile with one simple file
								upload.
							</p>
							<p className="fw-bold mb-5 gradient-text">
								Simply drag and drop as many resumes as you'd like and wathc
								Aira do the work!
							</p>
							<button
								className="btn"
								onClick={() =>
									window.open(AIRA_SIGNUP_LINK, "_blank", "noopener,noreferrer")
								}
							>
								Try Candidate Creation today&nbsp;
								<MoveRight />
							</button>
						</div>
					</div>
					<div className="col-md-6 col-sm-12 d-flex justify-content-center align-items-center order-md-last order-first">
						<img
							src={accurateDataImage}
							alt="Accurate Data Extraction"
							className="img-fluid"
							data-aos="fade-up"
						/>
					</div>
				</div>

				{/* Research */}
				<div className="row mt-5">
					<div className="col-md-6 col-sm-12 d-flex justify-content-center align-items-center">
						<img
							src={researchImage}
							alt="Research"
							className="img-fluid"
							data-aos="fade-up"
						/>
					</div>
					<div className="col-md-6 col-sm-12 d-flex justify-content-center align-items-center feature mt-4 mt-md-0">
						<div data-aos="fade-up" data-aos-delay="250">
							<h2 className="fw-bold mb-4">Research</h2>
							<p className="mb-5">
								Introduced to a new market? Search for industry insights, job
								market trends, new candidates - all within the system that
								learns as you do.
							</p>
							<button
								className="btn"
								onClick={() =>
									window.open(AIRA_APP_LINK, "_blank", "noopener,noreferrer")
								}
							>
								Discover more with our AI <MoveRight />
							</button>
						</div>
					</div>
				</div>

				{/* Contextual Memory */}
				<div className="row mt-5">
					<div className="col-md-6 col-sm-12 d-flex justify-content-center align-items-center feature order-md-first order-last mt-4 mt-md-0">
						<div data-aos="fade-up" data-aos-delay="250">
							<h2 className="fw-bold mb-4">Contextual Memory</h2>
							<p className="mb-5">
								An assistant that continues learning and never forgets -
								insights can always be brought back up when necessary, with
								contextual understanding. Just Ask Aira.
							</p>
							<button
								className="btn"
								onClick={() =>
									window.open(AIRA_APP_LINK, "_blank", "noopener,noreferrer")
								}
							>
								See AI Memory in Action <MoveRight />
							</button>
						</div>
					</div>
					<div className="col-md-6 col-sm-12 d-flex justify-content-center align-items-center order-md-last order-first">
						<img
							src={contextualMemoryImage}
							alt="Contextual Memory"
							className="img-fluid"
							data-aos="fade-up"
						/>
					</div>
				</div>

				{/* AI-Assistant */}
				<div className="row mt-5">
					<div className="col-md-6 col-sm-12 d-flex justify-content-center align-items-center">
						<img
							src={aiAssistantImage}
							alt="Research"
							className="img-fluid"
							data-aos="fade-up"
						/>
					</div>
					<div className="col-md-6 col-sm-12 d-flex justify-content-center align-items-center feature mt-md-0 mt-4">
						<div data-aos="fade-up" data-aos-delay="250">
							<h2 className="fw-bold mb-4">AI-Assistant</h2>
							<p className="mb-5">
								All the features of a traditional Applicant Tracking System
								(ATS) but with a twist. Simply chat with the system like you
								would with a friend. There is no longer the need to figure out
								complicated systems.
							</p>
							<button
								className="btn"
								onClick={() =>
									window.open(AIRA_APP_LINK, "_blank", "noopener,noreferrer")
								}
							>
								Interact with our Assistant <MoveRight />
							</button>
						</div>
					</div>
				</div>

				{/* Any Confusion */}
				<div className="row mt-5">
					<div className="col-md-6 col-sm-12 d-flex justify-content-center align-items-center feature order-md-first order-last mt-4 mt-md-0">
						<div data-aos="fade-up" data-aos-delay="250">
							<h2 className="fw-bold mb-4">Any confusion? Just Ask Aira.</h2>
							<p className="mb-5">
								Aira's here to help you with every step along the way. Curious
								as to how to best utilize Aira's abilities? Just Ask.
							</p>
							<button
								className="btn"
								onClick={() =>
									window.open(AIRA_APP_LINK, "_blank", "noopener,noreferrer")
								}
							>
								Try Ask AIRA <MoveRight />
							</button>
						</div>
					</div>
					<div className="col-md-6 col-sm-12 d-flex justify-content-center align-items-center order-md-last order-first">
						<img
							src={anyConfusionImage}
							alt="Contextual Memory"
							className="img-fluid"
							data-aos="fade-up"
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default FeaturesHighlight;
