import React, { useState, useEffect } from "react";
import "../styles/PricingPlan.css";

const PricingPlan = () => {
	const AIRA_SIGNUP_LINK = "https://app.helloaira.io/register";
	const AIRA_CALENDLY_LINK = "https://calendly.com/d/ck5g-53m-559/aira-demo";

	const [additionalRecords, setAdditionalRecords] = useState(0);
	const [parsingActions, setParsingActions] = useState(0);
	const [totalCost, setTotalCost] = useState(0);
	const [selectedPlan, setSelectedPlan] = useState(null);

	const plans = [
		{
			name: "Single Recruiter",
			price: "$89/month",
			users: "1 user",
			action: "Get Started",
			features: [
				"AI-powered assistance embedded in app pages for seamless navigation and tasks",
				"Core ATS functionalities",
				"AI-driven email plugin for communication",
				"Candidate/job/client shortlisting",
				"Free job board integration",
				"Job Board Data Integration: Extract relevant job data from Seek, LinkedIn, and Free Job Boards",
			],
			records: 5000,
			parsingActions: 200,
		},
		{
			name: "Starter Plan",
			price: "Contact Us",
			users: "Up to 5 Users",
			action: "Contact Sales",
			features: [
				"AI-powered assistance embedded in app pages for seamless navigation and tasks",
				"Core ATS functionalities",
				"AI-driven email plugin for communication",
				"Candidate/job/client shortlisting",
				"Free job board integration",
				"Job Board Data Integration: Extract relevant job data from Seek, LinkedIn, and Free Job Boards",
				"Dashboard: Job, candidate and activity pipeline",
			],
			records: 10000,
			parsingActions: 600,
		},
		{
			name: "Team Plan",
			price: "Contact Us",
			users: "Up to 50 Users",
			action: "Contact Sales",
			features: [
				"AI-powered assistance embedded in app pages for seamless navigation and tasks",
				"Everything in Starter Plan",
				"Advanced job board data integrations (LinkedIn, Seek, Free Job Boards)",
				"Custom export templates",
				"Automated shortlisting",
				"AI assistant embedded within pages",
				"Custom field configurations",
				"Job Board Data Integration: Extract relevant job data from Seek, LinkedIn, and Free Job Boards",
				"Dashboard: Job, candidate and activity pipeline",
			],
			records: 50000,
			parsingActions: 1000,
		},
		{
			name: "Enterprise Plan",
			price: "Contact Us",
			users: "Unlimited Users",
			action: "Contact Sales",
			features: [
				"AI-powered assistance embedded in app pages for seamless navigation and tasks",
				"Everything in Team Plan",
				"Fully branded customizable job portal",
				"Dashboard for job, candidate, and activity pipelines",
				"LinkedIn job posting automation",
				"Priority customer support",
				"Advanced analytics and reporting tools",
				"Job Board Data Integration: Extract relevant job data from Seek, LinkedIn, and Free Job Boards",
				"Dashboard: Job, candidate and activity pipeline",
			],
			records: "Unlimited",
			parsingActions: "Unlimited",
		},
	];

	useEffect(() => {
		const recordRate = 40; // $40 per 10000 additional records
		const parsingRate = 20; // $20 per 200 parsing actions

		const cost =
			(additionalRecords / 10000) * recordRate +
			(parsingActions / 200) * parsingRate;
		setTotalCost(cost);
	}, [additionalRecords, parsingActions]);

	// Helper function to handle input change
	const handleInputChange = setter => e => {
		const re = /^[0-9\b]+$/;

		// if value is not blank, then test the regex
		if (e.target.value === "" || re.test(e.target.value)) {
			const value = e.target.value;
			setter(value);
		}
	};

	return (
		<div className="mt-5 mb-5">
			<div className="container">
				<div className="text-center">
					<h1 className="mb-4 fw-bold">Pricing Plans</h1>
					<p>
						Find the perfect plan for your needs—simple,
						<br />
						flexible, and tailored for you!
					</p>
				</div>
			</div>

			{/* Price Plan Columns */}
			<div className="container">
				<div className="pricing-grid">
					{plans.map((plan, index) => (
						<div className="wrapper">
							<div
								key={index}
								className={`pricing-column ${
									selectedPlan === plan.name ? "selected" : ""
								}`}
								onClick={() => setSelectedPlan(plan.name)}
							>
								<div className="column-wrapper">
									<h2 className="fs-4 fw-bold">{plan.name}</h2>
									<div className="fs-2 price fw-bold text-start">
										{plan.price}
									</div>
									<p className="m-0">{plan.users}</p>
									<p>Records: {plan.records}</p>
									<div className="text-center">
										<a
											href={
												plan.action === "Get Started"
													? AIRA_SIGNUP_LINK
													: AIRA_CALENDLY_LINK
											}
											className="btn w-100"
											target="_blank"
											rel="noopener noreferrer"
										>
											{plan.action}
										</a>
									</div>
									<ul className="list-unstyled features mt-4">
										{plan.features.map((feature, index) => (
											<li
												key={index}
												className={
													feature === "Everything in Starter Plan" ||
													feature === "Everything in Team Plan"
														? "fw-bold"
														: ""
												}
											>
												{feature}
											</li>
										))}
									</ul>
								</div>
							</div>
						</div>
					))}
				</div>
			</div>

			{/* Add-ons */}

			<div className="container">
				<div
					className={`add-ons mt-5 ${
						selectedPlan !== "Enterprise Plan" ? "show" : ""
					}`}
				>
					<h2 className="fw-bold">Add-ons</h2>
					<div className="row">
						{/* Additional records price calculator */}
						<div className="col-md-6 col-12">
							<form className="addon-calculator mt-4">
								<div className="form-group">
									<label htmlFor="additionalRecords">Additional Records:</label>
									<input
										type="number"
										id="additionalRecords"
										className="form-control"
										value={additionalRecords}
										onChange={handleInputChange(setAdditionalRecords)}
										inputMode="numeric"
										pattern="[0-9]*"
									/>
								</div>
								<div className="form-group mt-3">
									<label htmlFor="parsingActions">Parsing Actions:</label>
									<input
										type="number"
										id="parsingActions"
										className="form-control"
										value={parsingActions}
										onChange={handleInputChange(setParsingActions)}
										inputMode="numeric"
										pattern="[0-9]*"
									/>
								</div>
							</form>
						</div>
						<div className="col-md-6 col-12">
							<div className="d-flex align-items-center h-100">
								<div className="text-md-end text-start mt-3 w-100">
									<p className="fs-3 m-0">Additional Cost:</p>
									<span className="fw-bold fs-2">
										${totalCost !== null ? totalCost.toFixed(2) : "0.00"}
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PricingPlan;
