import React from "react";
import Testimonial from "./ui/Testimonial/Testimonial";
import "../styles/Testimonials.css";

const testimonialsData = [
	{
		id: 1,
		name: "Daniel B.",
		company: "Enigma Recruitment",
		role: "Industrial Recruitment",
		review: 5,
		quote:
			"I thought our current Recruitment software was the best in the market until having hands on experience with AIRA. There are lots of updates being rolled out by the team and we are very actively keeping up to date with what is happening as the potential for AIRA and the time/efficiency it can bring is exponential.",
	},
	{
		id: 2,
		name: "Pisey K.",
		company: "Workingna",
		role: "HR & Recruitment",
		review: 5,
		quote:
			"Aira has transformed the way we work by streamlining processes and allowing us to focus on strategic tasks, which has significantly boosted our team’s productivity. Its accuracy and reliability have exceeded our expectations, enabling us to enhance customer service satisfaction.",
	},
	// Add more testimonials as needed
];

const Testimonials = () => {
	return (
		<div className="testimonials mt-5 py-5">
			<div className="container">
				<div className="text-center">
					<h2>We Made Hiring Easier!</h2>
					<p>See what our users say</p>
				</div>
				<div className="content-wrapper">
					<div className="py-5">
						<ul className="list-unstyled row justify-content-center">
							{testimonialsData.map(testimonial => (
								<li className="col-lg-4 col-sm-6 col-12 pb-3">
									<Testimonial
										key={testimonial.id}
										name={testimonial.name}
										company={testimonial.company}
										role={testimonial.role}
										review={testimonial.review}
										quote={testimonial.quote}
									/>
								</li>
							))}
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Testimonials;
