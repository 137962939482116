import React, { useEffect } from "react";
import HeroBanner from "../components/HeroBanner";
import VideoSection from "../components/VideoSection";
import HiredEasily from "../components/HiredEasily";
import FeaturesHighlight from "../components/FeaturesHighlight";
import NativelyBuilt from "../components/NativelyBuilt";
import PricingLink from "../components/PricingLink";
import Testimonials from "../components/Testimonials";
import ReadyStarted from "../components/ReadyStarted";
import Footer from "../Common/Footer";
import { scroller } from "react-scroll";
import { Helmet } from "react-helmet";
import "../styles/Home.css";

const Home = () => {
	useEffect(() => {
		// Scroll to id element on the page load
		const hash = window.location.hash;
		if (hash) {
			const id = hash.replace("#", "");
			scroller.scrollTo(id, {
				duration: 800,
				delay: 0,
				smooth: "easeInOutQuart",
			});
		}
	}, []);

	return (
		<div>
			<Helmet>
				<title>Aira</title>
				<meta name="title" content="Aira" />

				{/* <!-- Open Graph / Facebook --> */}
				<meta
					property="og:image"
					content="/assets/images/meta/aira_social_media_preview.png"
				/>

				{/* <!-- Twitter --> */}
				<meta
					property="twitter:image"
					content="/assets/images/meta/aira_social_media_preview.png"
				/>
			</Helmet>
			<HeroBanner />
			<VideoSection />
			<div className="feature-highlight">
				<HiredEasily />
				<FeaturesHighlight />
			</div>
			<NativelyBuilt />
			<PricingLink />
			<Testimonials />
			<ReadyStarted />
			<Footer />
		</div>
	);
};

export default Home;
