import React from "react";
import "../styles/NativelyBuilt.css";
import airaLogo from "../assets/images/aira-logo.svg";

const NativelyBuilt = () => {
	const AIRA_REGISTER_LINK = "https://app.helloaira.io/register";
	const BOOK_DEMO_LINK = "https://calendly.com/d/ck5g-53m-559/aira-demo";

	return (
		<div className="natively-built">
			<h2>We're natively built on AI</h2>
			<div className="content-wrapper">
				<div className="card-container">
					<div className="info-card card-details gradient-vertical">
						<h3>
							We take care
							<br />
							of the <em>details.</em>
						</h3>
						<p>
							We handle it all - from sourcing candidates, to communications, to
							notifying your team members.
						</p>
					</div>
					<div className="info-card card-get-better gradient-vertical">
						<h3>
							We get <em>better</em>
							<br />
							every time.
						</h3>
						<p>
							Every interaction allows Aira to get smarter - learning how best
							to serve you.
						</p>
					</div>
					<div className="center-logo">
						<div className="logo-text fs-1">At</div>
						<div className="logo-brand">
							<img src={airaLogo} alt="Aira Logo" />
						</div>
					</div>
					<div className="circle-wrapper">
						<div className="circle-pulse">
							<div className="circle-out"></div>
							<div className="circle-out"></div>
							<div className="circle-out"></div>
						</div>
						<div className="white-circle"></div>
					</div>
					<button
						className="action-card btn-join gradient-3"
						onClick={() =>
							window.open(AIRA_REGISTER_LINK, "_blank", "noopener,noreferrer")
						}
					>
						Join beta testing
					</button>
					<button
						className="action-card btn-demo gradient-4"
						onClick={() =>
							window.open(BOOK_DEMO_LINK, "_blank", "noopener,noreferrer")
						}
					>
						Book a demo
					</button>
				</div>
			</div>
		</div>
	);
};

export default NativelyBuilt;
