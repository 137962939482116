import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/ReadyStarted.css";

const ReadyStarted = () => {
	const BOOK_DEMO_LINK = "https://calendly.com/d/ck5g-53m-559/aira-demo";
	return (
		<div className="ready-started py-5">
			<div className="container">
				<div className="row align-items-center px-5">
					<div className="col-md-6 col-12 text-md-start text-center">
						<h2 className="ready-title">Ready to Get started?</h2>
						<p className="ready-description">Sign Up or Contact Us</p>
					</div>
					<div className="col-md-6 col-12 text-md-end text-center">
						<div className="d-flex flex-wrap justify-content-evenly">
							<div>
								<button
									role="link"
									className="btn btn-book-demo border border-0"
									onClick={() =>
										window.open(BOOK_DEMO_LINK, "_blank", "noopener,noreferrer")
									}
								>
									Book A Demo
								</button>
							</div>
							<div className="mt-lg-0 mt-3">
								<a
									role="link"
									href="mailto:contact@helloaira.io"
									title="contact@helloaira.io"
								>
									<button className="btn btn-contact-us">
										Contact Us
									</button>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ReadyStarted;
