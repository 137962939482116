import React from "react";
import arrowIcon from "../assets/icons/right.png";
import "../styles/HeroBanner.css";
import "../styles/Popup.css";

const HeroBanner = () => {
	return (
		<div className="container-fluid py-5 hero-banner">
			<div className="d-flex flex-column align-items-center">
				<div className="d-flex align-items-center px-3 py-2 mb-3 feature-button">
					<span className="badge-gradient me-2">New</span>
					<span className="fs-5 feature-text">
						Updated search features
					</span>
					<img src={arrowIcon} alt="Arrow Icon" className="ms-2" />
				</div>
				<h1 className="fw-bold text-black text-center mb-3 py-3">
					Effortless Recruitment with Agentic AI.
				</h1>
				<p className="fs-5 text-black text-center mb-3 blog-size">
					Aira streamlines hiring with intuitive, scalable solutions, enabling
					<br />
					seamless collaboration and unparalleled efficiency.
				</p>
				<button
					className="btn aira-background-gradient py-3 mt-3"
					onClick={() =>
						(window.location.href =
							"https://calendly.com/d/ck5g-53m-559/aira-demo")
					}
				>
					Book A Demo!
				</button>
			</div>
		</div>
	);
};

export default HeroBanner;
