import React from "react";
import { Star } from "lucide-react";
import testimonialCircles from "../../../assets/icons/testimonial-circles.svg";
import "./Testimonial.css";

const Testimonial = ({ key, name, company, role, review, quote }) => {
	const renderStars = count => {
		return Array.from({ length: count }).map(() => (
			<Star fill="#283F6C" strokeWidth={0} />
		));
	};

	return (
		<div className="testimonial-card" key={key}>
			<div className="testimonial-details mb-3">
				<img className="circles" src={testimonialCircles} alt="Testimonial Circle Icon" />
				<span className="d-inline-block">
					<h3 className="mb-0">{name}</h3>
					<p className="mb-0">
						hired at <em>{company}</em> in <em>{role}</em>
					</p>
				</span>
			</div>
			<p>review {renderStars(review)}</p>
			<div className="quote">
				<p className="fs-6 m-0">"{quote}"</p>
			</div>
		</div>
	);
};

export default Testimonial;
